const SET_LIVE_BROADCAST_LINK = (state, payload) => {
  state.liveBroadcastLink = payload;
}

const CLEAR_LIVE_BROADCAST_LINK = (state) => {
  state.liveBroadcastLink = null;
}

const SET_SCHEDULED_LIVE_BROADCAST = (state, payload) => {
  state.scheduledLiveBroadcast = payload;
}

const CLEAR_SCHEDULED_LIVE_BROADCAST = (state) => {
  state.scheduledLiveBroadcast = null;
}

const SET_SCHEDULES = (state, payload) => {
  state.schedules = payload;
}

export {
  SET_LIVE_BROADCAST_LINK,
  CLEAR_LIVE_BROADCAST_LINK,
  SET_SCHEDULED_LIVE_BROADCAST,
  CLEAR_SCHEDULED_LIVE_BROADCAST,
  SET_SCHEDULES,
}