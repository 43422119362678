const SET_RECENT_SONGS = (state, payload) => {
  state.recentSongs = payload;
};

const CLEAR_RECENT_SONGS = (state) => {
  state.recentSongs = {};
};

const SET_PLAYLIST_SONG_IDS = (state, payload) => {
  state.playlistSongIds = payload;
}

const CLEAR_PLAYLIST_SONG_IDS = (state) => {
  state.playlistSongIds = {};
};

const SET_PLAYLIST_BANNERS = (state, payload) => {
  state.playlistBanners = payload;
};

const CLEAR_PLAYLIST_BANNERS = (state) => {
  state.playlistBanners = [];
};

const SET_MUSIC_PLAYLIST_BANNERS = (state, payload) => {
  state.musPlaylistBanners = payload;
}

export {
  SET_RECENT_SONGS,
  CLEAR_RECENT_SONGS,
  SET_PLAYLIST_SONG_IDS,
  CLEAR_PLAYLIST_SONG_IDS,
  SET_PLAYLIST_BANNERS,
  CLEAR_PLAYLIST_BANNERS,
  SET_MUSIC_PLAYLIST_BANNERS,
}