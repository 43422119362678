import Axios from '../../../axios-auth';

const getIssueCategories = ({ commit }) => {
  Axios.get('/api/issues/categories')
    .then(response => {
      commit('SET_ISSUE_CATEGORIES', response.data);
    })
}

export {
  getIssueCategories,
}