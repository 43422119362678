import Axios from '../../../axios-auth';

const getInteractiveCategories = ({ commit }) => {
  Axios.get('api/interact/categories')
    .then(response => {
      const result = response.data.result;
      commit('SET_INTERACTIVE_CATEGORIES', result);
      commit('SET_CURRENT_CATEGORY', result[0]);
    });
}

const setCurrentCategory = ({ commit }, category) => {
  commit('SET_CURRENT_CATEGORY', category);
}

export {
  getInteractiveCategories,
  setCurrentCategory,
}