import Axios from '../../../axios-auth';

const getSettings = ({ commit, dispatch }) => {
  Axios.get('api/web/settings')
    .then(response => {
      localStorage.settings = JSON.stringify(response.data.data);
      commit('SET_SETTINGS', response.data.data);

      const settings = response.data.data;
      const mode = settings.find(obj => obj.name === 'maintenance');
      dispatch('setMaintenance', mode.value);
    });
};

const setMaintenance = ({ commit }, value) => {
  localStorage.maintenance = value;
  commit('SET_IS_MAINTENANCE', value);
}

export {
  getSettings,
  setMaintenance,
}