import Axios from '../../../axios-auth';

const getMusic = ({ commit }) => {
  Axios.get('api/mymusic')
    .then(response => {
      const musicIds = response.data.map(item => item.mobile_music_id);
      commit('SET_FAVORITE_MUSIC', response.data);
      commit('SET_FAVE_MUSIC_IDS', musicIds);
    })
    .catch(() => {
      commit('SET_FAVORITE_MUSIC', []);
      commit('SET_FAVE_MUSIC_IDS', []);
    });
}

const getVideos = ({ commit }) => {
  Axios.get('api/myvideos')
    .then(response => {
      const videoIds = response.data.map(item => item.media_id);
      commit('SET_FAVORITE_VIDEOS', response.data);
      commit('SET_FAVE_VIDEO_IDS', videoIds);
    })
    .catch(() => {
      commit('SET_FAVORITE_VIDEOS', []);
      commit('SET_FAVE_VIDEO_IDS', []);
    });
}

export {
  getMusic,
  getVideos,
}