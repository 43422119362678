import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  info            : {},
  userContents    : {},
  musicFavorites  : [],
  musicFaveStatus : null,
  videoFavorites  : [],
  initVideoFave   : {},
  videoFaveStatus : null,
  recentlyPlayed  : [],
};

export default {
  namespaced : true,
  state,
  actions,
  mutations,
  getters,
}