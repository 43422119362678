import Axios from '../../../axios-auth';
import _ from 'lodash';
const moment = require("moment-timezone");

const getUnreadAnnouncements = async ({ commit }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const unread = [];
  let sent = [];
  let read = [];

  // Get read announcement per user
  await Axios.get('api/announcement/read', {
    params : {
      userId : user.user_id,
    },
  }).then(response => {
    read = response.data.data;
  });

  // Get sent based on current date
  await Axios.get('api/announcement/sent', {
    params : {
      countryId   : user.school[0] ? user.school[0].country_id : null,
      stateId     : user.school[0] ? user.school[0].state_id : null,
      cityId      : user.school[0] ? user.school[0].city_id : null,
      schoolId    : user.school[0] ? user.school[0].school_id : null,
      currentDate : moment.utc().format('YYYY-MM-DD HH:mm'),
      platform    : 'website',
    },
  }).then(response => {
    sent = response.data.data;
  });

  if (sent) {
    sent.forEach(item => {
      const foundRead = _.find(read, function(e) {
        return e.announcement_id === item.announcement_id;
      });

      if (!foundRead && item.platform !== 'mobile')
        unread.push(item);
    });

    commit('SET_UNREAD_ANNOUNCEMENTS', unread);
  }
}

export {
  getUnreadAnnouncements,
}