const SET_ALBUMS = (state, payload) => {
  state.albums = payload;
};

const SET_ALBUM = (state, payload) => {
  state.album = payload;
};

const SET_ALBUM_SONGS = (state, payload) => {
  state.albumSongs = payload;
};

const CLEAR_ALBUM_SONGS = (state) => {
  state.albumSongs = null;
};

const REMOVE_ALBUM_SONG = (state, payload) => {
  // eslint-disable-next-line no-undef
  state.albumSongs = _.remove(state.albumSongs, function (n) {
    return n !== payload;
  });
};

const SET_CHALLENGES_ALBUM = (state, payload) => {
  state.challengesAlbum = payload;
};

const SET_CHALLENGES_ALBUM_SONGS = (state, payload) => {
  state.challengesAlbumSongs = payload;
};

const CLEAR_CHALLENGES_ALBUM_SONGS = (state) => {
  state.challengesAlbumSongs = null;
};

const REMOVE_CHALLENGES_ALBUM_SONG = (state, payload) => {
  // eslint-disable-next-line no-undef
  state.challengesAlbumSongs = _.remove(state.challengesAlbumSongs,
    function (n) {
      return n !== payload;
    });
};

const SET_FEATURED_VIDEOS = (state, payload) => {
  state.featuredVideos = payload;
}

const SET_NEW_VIDEOS = (state, payload) => {
  state.newVideos = payload;
}

export {
  SET_ALBUMS,
  SET_ALBUM,
  SET_ALBUM_SONGS,
  CLEAR_ALBUM_SONGS,
  REMOVE_ALBUM_SONG,
  SET_CHALLENGES_ALBUM,
  SET_CHALLENGES_ALBUM_SONGS,
  CLEAR_CHALLENGES_ALBUM_SONGS,
  REMOVE_CHALLENGES_ALBUM_SONG,
  SET_FEATURED_VIDEOS,
  SET_NEW_VIDEOS,
}