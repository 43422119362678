const SET_CATEGORIES = (state, payload) => {
  state.categories = payload;
}

const SET_INTERACT_CATEGORIES = (state, payload) => {
  state.interactCategories = payload;
}

const SET_CHARACTERS_MEDIA = (state, payload) => {
  state.charsMedia = payload;
}

export {
  SET_CATEGORIES,
  SET_INTERACT_CATEGORIES,
  SET_CHARACTERS_MEDIA,
}