import Axios from '../../../axios-auth';
import Router from '../../../routes';

const getUser = ({ commit, rootState }) => {
  if (!rootState.auth.accessToken)
    return;

  Axios.get('/auth/me')
    .then(response => {
      commit('SET_USER', response.data);
      localStorage.user = JSON.stringify(response.data);

      switch(response.data.user_type) {
      case 0:
        Router.replace('/admin/dashboard');
        break;
      case 6:
        Router.replace('/subscription');
        break;
      default:
        Router.replace('/dashboard');
        break;
      }
      
      /**
       * Original code
       */
      // if (response.data.user_type === 0)
      //   Router.replace('/admin/dashboard'); // redirect to Admin
      // else if (response.data.user_type === 6)
      //   Router.replace('/subscription'); // redirect to Subscription
      // else
      //   Router.replace('/dashboard');

      // TODO: Transfer this to auth/login
      // if (localStorage.getItem('regPath') === '/pricing')
      //   console.log("Nothing to do -- Registration via Pricing page");
      // else if (localStorage.getItem('socialRegPath') === '/pricing')
      //   Router.replace('/pricing/2');
      // else {
      //   if (response.data.user_type !== 0) {
      //     if (response.data.user_type === 6)
      //       Router.replace('/subscription'); // redirect to subscription page
      //     else {
      //       // redirect to dashboard page
      //       Router.replace('/dashboard'); //('/browse/main');
      //     }
      //   } else
      //     Router.replace('/admin/dashboard'); // redirect to admin
      // }
    });
}

// eslint-disable-next-line no-undef
const getUpdatedUser = _.debounce(function({ commit, rootState }) {
  if (!rootState.auth.accessToken)
    return;

  Axios.get('/auth/me')
    .then(response => {
      commit('SET_USER', response.data);
      localStorage.user = JSON.stringify(response.data);
    });
}, 1000);

const clearUser = ({ commit }) => {
  commit('CLEAR_USER', {});
};

export {
  getUser,
  getUpdatedUser,
  clearUser,
}
