import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  subscription : {},
  payment      : {},
  users        : {},
  plans        : [],
  userProfiles : [],
};

export default {
  namespaced : true,
  state,
  actions,
  mutations,
  getters,
}