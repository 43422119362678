const SET_INFO = (state, payload) => {
  state.info = payload;
};

const SET_USER_CONTENTS = (state, payload) => {
  state.userContents = payload;
}

const SET_MUSIC_FAVORITES = (state, payload) => {
  state.musicFavorites = payload;
};

const SET_VIDEO_FAVORITES = (state, payload) => {
  state.videoFavorites = payload;
};

const SET_RECENTLY_PLAYED = (state, payload) => {
  state.recentlyPlayed = payload;
};

const SET_INIT_VIDEO_FAVE = (state, payload) => {
  state.initVideoFave = payload;
}

const SET_VIDEO_FAVE_STATUS = (state, payload) => {
  state.videoFaveStatus = payload;
}

const SET_MUSIC_FAVE_STATUS = (state, payload) => {
  state.musicFaveStatus = payload;
}

export {
  SET_INFO,
  SET_USER_CONTENTS,
  SET_MUSIC_FAVORITES,
  SET_VIDEO_FAVORITES,
  SET_RECENTLY_PLAYED,
  SET_INIT_VIDEO_FAVE,
  SET_VIDEO_FAVE_STATUS,
  SET_MUSIC_FAVE_STATUS,
}