const albums = state => state.albums;
const album = state => state.album;
const albumSongs = state => state.albumSongs;
const challengesAlbum = state => state.challengesAlbum;
const challengesAlbumSongs = state => state.challengesAlbumSongs;
const featuredVideos = state => state.featuredVideos;
const newVideos = state => state.newVideos;

export {
  albums,
  album,
  albumSongs,
  challengesAlbum,
  challengesAlbumSongs,
  featuredVideos,
  newVideos,
}