import Axios from '../../../axios-auth';

const getSubscription = ({ commit }, userId) => {
  const user = userId ? userId : JSON.parse(localStorage.user).user_id;
  
  Axios.get('api/subscription', {
    params : {
      userId : user,
    },
  }).then(response => {
    commit('SET_SUBSCRIPTION', response.data);
  }).catch(() => {
    commit('CLEAR_SUBSCRIPTION');
    commit('CLEAR_PAYMENT');
  });
}

const getStripePayment = async ({ commit }, paymentId) => {
  await Axios.get('api/stripe/paymentMethod', {
    params : {
      stripePaymentMethodId : paymentId,
    },
  }).then(response => {
    commit('SET_PAYMENT', response.data.data);
  }).catch(() => {
    commit('CLEAR_PAYMENT');
  });
}

const getPaypalSubscription = async ({ commit }, paypalSubscriptionId) => {
  Axios.get('api/paypal/subscription', {
    params : {
      paypalSubscriptionId : paypalSubscriptionId,
    },
  }).then(response => {
    const resp = response.data.data;
    const payment = {
      name : resp.subscriber.name.given_name + ' ' +
        resp.subscriber.name.surname,
      email         : resp.subscriber.email_address,
      paymentMethod : 'paypal',
    };
    commit('CLEAR_PAYMENT');
    commit('SET_PAYMENT', payment);
  }).catch(() => {
    commit('CLEAR_PAYMENT');
  });
}

const getSubscriptionUsers = async ({ commit }, id) => {
  Axios.get('api/subscription/users', {
    params : {
      subscriptionId : id,
      page           : 1,
      perPage        : 100,
    },
  }).then(response => {
    commit('SET_SUBSCRIPTION_USERS', response.data);
  }).catch(() => {
    commit('SET_SUBSCRIPTION_USERS', {});
  });
}

const getPlans = async ({ commit }) => {
  Axios.get('api/subscription/plans', {
    params : {
      app : 'website',
    },
  })
    .then(response => {
      commit('SET_SUBSCRIPTION_PLANS', response.data);
    })
    .catch(() => {
      commit('SET_SUBSCRIPTION_PLANS', []);
    });
}

const getUserProfiles = async ({ commit }) => {
  Axios.get('api/user/profiles')
    .then(response => {
      commit('SET_USER_PROFILES', response.data.data);
    }).catch(() => {
      commit('SET_USER_PROFILES', []);
    });
}

export {
  getSubscription,
  getStripePayment,
  getPaypalSubscription,
  getSubscriptionUsers,
  getPlans,
  getUserProfiles,
}