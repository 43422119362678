import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  albums               : [],
  album                : {},
  albumSongs           : null,
  challengesAlbum      : null,
  challengesAlbumSongs : null,
  featuredVideos       : [],
  newVideos            : [],
};

export default {
  namespaced : true,
  state,
  actions,
  mutations,
  getters,
};