const adminNotifs = state => state.adminNotifs;
const adminBarNotifs = state => state.adminBarNotifs;
const adminListNotifs = state => state.adminListNotifs;
const activityNotifs = state => state.activityNotifs;

export {
  adminNotifs,
  adminBarNotifs,
  adminListNotifs,
  activityNotifs,
}