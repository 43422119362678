const SET_SETTINGS = (state, payload) => {
  state.settings = payload;
}

const SET_IS_MAINTENANCE = (state, payload) => {
  state.isMaintenance = payload;
}

export {
  SET_SETTINGS,
  SET_IS_MAINTENANCE,
}