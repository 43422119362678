const SET_TOKENS = (state, payload) => {
  state.accessToken = payload.accessToken;
  state.refreshToken = payload.refreshToken;
}

const CLEAR_TOKENS = (state) => {
  state.accessToken = null;
  state.refreshToken = null;
}

const AUTH_REQUEST = (state) => {
  state.status = 'loading';
}

const AUTH_SUCCESS = (state) => {
  state.status = 'success';
}

const AUTH_ERROR = (state) => {
  state.status = 'error';
}

const SET_PIN_VER = (state, payload) => {
  state.isPinVerified = payload;
}

const SET_LOGOUT_STATUS = (state, payload) => {
  state.logoutStatus = payload;
}

export {
  SET_TOKENS,
  CLEAR_TOKENS,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  SET_PIN_VER,
  SET_LOGOUT_STATUS,
}