const recentSongs = state => state.recentSongs;
const playlistSongIds = state => state.playlistSongIds;
const playlistBanners = state => state.playlistBanners;
const musPlaylistBanners =state => state.musPlaylistBanners;

export {
  recentSongs,
  playlistSongIds,
  playlistBanners,
  musPlaylistBanners,
}