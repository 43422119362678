import Axios from '../../../axios-auth';

const getInfo = ({ commit }, profileId) => {
  if (profileId) {
    Axios.get('api/user/profile', {
      params : {
        userProfileId : profileId,
      },
    }).then(response => {
      commit('SET_INFO', response.data.data);
    }).catch(() => {
      commit('SET_INFO', {});
    });
  } else {
    const user = JSON.parse(localStorage.getItem('user'));
    commit('SET_INFO', user);
  }
};

const getUserContents = ({ commit, rootState}, page) => {
  const user = rootState.user.user;
  const profile = rootState.profile.info;
  const contents = rootState.profile.userContents;

  Axios.get('api/user/content', {
    params : {
      userId  : user.user_id,
      profId  : (profile.user_profiles_id) ? profile.user_profiles_id : null,
      page    : page,
      perPage : 6,
    },
  }).then(response => {
    if (response.data.currentPage > 1) {
      const arr = response.data.data;
      const res = contents.data.concat(arr);
      response.data.data = res;
      commit('SET_USER_CONTENTS', response.data);
    } else
      commit('SET_USER_CONTENTS', response.data);
  }).catch(() => {
    commit('SET_USER_CONTENTS', {});
  });
};

const getMusicFavorites = ({ commit, rootState }) => {
  commit('SET_MUSIC_FAVE_STATUS', 'loading');
  const profile = rootState.profile.info;
  const params = {};
  if (profile.user_profiles_id)
    params.profileId = profile.user_profiles_id;

  Axios.get('api/mymusic', {
    params : params,
  }).then(response => {
    commit('SET_MUSIC_FAVE_STATUS', 'success');
    commit('SET_MUSIC_FAVORITES', response.data);
  }).catch(() => {
    commit('SET_MUSIC_FAVE_STATUS', 'error');
    commit('SET_MUSIC_FAVORITES', []);
  });
}

const getVideoFavorites = ({ commit, rootState }) => {
  commit('SET_VIDEO_FAVE_STATUS', 'loading');
  const profile = rootState.profile.info;
  const params = {};
  if (profile.user_profiles_id)
    params.profileId = profile.user_profiles_id;

  Axios.get('api/myvideos', {
    params : params,
  }).then(response => {
    commit('SET_VIDEO_FAVE_STATUS', 'success');
    commit('SET_VIDEO_FAVORITES', response.data);
    if (response.data.length > 0)
      commit('SET_INIT_VIDEO_FAVE', response.data[0]);
  }).catch(() => {
    commit('SET_VIDEO_FAVE_STATUS', 'error');
    commit('SET_VIDEO_FAVORITES', []);
    commit('SET_INIT_VIDEO_FAVE', {});
  });
}

export {
  getInfo,
  getUserContents,
  getMusicFavorites,
  getVideoFavorites,
}