/* eslint-disable */
<template>
  <div id="app">
    <notif-listener v-if="isLoggedIn" />
    <announcement-listener v-if="isLoggedIn" />
    <router-view/>
    <notifications
      classes="vue-notification notif-notification"
      group="notif"
      :ignoreDuplicates="true"
    ></notifications>

    <!--validation notification-->
    <notifications
      classes="vue-notification validation-notification"
      group="validation-notification"
      position="top center"
      :ignoreDuplicates="true"
    ></notifications>
    <UpdatesListener />
  </div>
</template>


<script>
  import 'bootstrap';
  import 'bootstrap/dist/css/bootstrap.css';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'bootstrap/js/dist/util';
  import 'bootstrap-vue/dist/bootstrap-vue.css';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    name : 'app',
    data() {
      return {
        workerInterval : null,
      };
    },
    head : {
      title : {
        inner      : 'Site',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
      meta : [
        {
          name    : 'description',
          content : 'Get into the healthy lifestyle with Healthy Hip Hop! ' +
            'Dance and learn through our three M’s: Music, Movement and ' +
            'Message.',
          id : 'app-description',
        },

        // Twitter
        {
          name    : 'twitter:title',
          content : 'Healthy Hiphop',
          id      : 'twitter-title',
        },
        {
          name    : 'twitter:description',
          content : 'Get into the healthy lifestyle with Healthy Hip Hop! ' +
            'Dance and learn through our three M’s: Music, Movement and ' +
            'Message.',
          id : 'twitter-description',
        },

        // Google+ / Schema.org
        {
          itemprop : 'name',
          content  : 'Healthy Hiphop',
        },
        {
          itemprop : 'description',
          content  : 'Get into the healthy lifestyle with Healthy Hip Hop! ' +
            'Dance and learn through our three M’s: Music, Movement and ' +
            'Message.',
        },

        // Facebook / Open Graph
        {
          property : 'og:title',
          content  : 'Healthy Hiphop',
          id       : 'og-title',
        },
        {
          property : 'og:description',
          content  : 'Get into the healthy lifestyle with Healthy Hip Hop! ' +
            'Dance and learn through our three M’s: Music, Movement and ' +
            'Message.',
          id : 'og-title',
        },
        {
          property : 'og:image',
          content  : 'https://s3-us-west-2.amazonaws.com/' +
            'h3-admin-bucket-prod/new_banner.png',
          id : 'og-image',
        },
      ],
    },
    computed : {
      ...mapGetters({
        user     : 'user/user',
        settings : 'web/settings',
      }),
      isLoggedIn() {
        const isLoggedIn = (this.$store.getters['auth/isLoggedIn'] &&
          this.$store.getters['user/user']) ? true : false;

        return isLoggedIn;
        // return this.$store.getters['auth/isLoggedIn'];
      },
    },
    components : {
      NotifListener        : () => import('@/components/notification/Listener'),
      AnnouncementListener : () => import('@/components/announcement/Listener'),
      UpdatesListener      : () => import('@/components/listener/Updates'),
    },
    watch : {
      '$store.state.notification.error'() {
        const msg = this.$store.state.notification.error;
        if (msg)
          this.showError(msg);
      },
    },
    methods : {
      ...mapActions({
        'getUpdatedUser' : 'user/getUpdatedUser',
      }),

      /**
       * Show Error message notification
       *
       * @param msg
       */
      showError(msg) {
        this.$notify({
          group    : 'validation-notification',
          type     : 'error',
          duration : 3000,
          text     : msg,
        });
        this.$store.commit('notification/CLEAR_ERROR');
      },
    },
    mounted() {
      this.$store.dispatch('web/getSettings');
    },
    updated() {
      if (this.isLoggedIn) {
        if (this.workerInterval === null) {
          this.workerInterval = setInterval(() => {
            this.getUpdatedUser();
          }, 10000); // 10 sec
        }

      } else {
        if (this.workerInterval)
          clearInterval(this.workerInterval);

        this.workerInterval = null;
      }
    },
  };
</script>

<style lang="scss">
  @import "assets/scss/app";
</style>
