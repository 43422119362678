import Axios from '../../../axios-auth';

const getCountries = ({ commit }) => {
  Axios.get('api/country/list')
    .then(response => {
      commit('SET_COUNTRIES', response.data);
    });
}

export {
  getCountries,
}