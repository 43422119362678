import Axios from '../../../axios-auth';

const getAlbums = ({ commit }) => {
  Axios.get('api/albums', {
    params : {
      page    : 1,
      perPage : 1000,
    },
  }).then(response => {
    commit('SET_ALBUMS', response.data.data);
  });
}

const getAlbum = ({ commit }, albumId) => {
  Axios.get('mobile/api/album', {
    params : {
      id : albumId,
    },
  }).then(response => {
    commit('SET_ALBUM', response.data.item);
  })
}

const getAlbumSongs = ({ commit }, id) => {
  Axios.get('/mobile/api/album/songs', {
    params : {
      mobileAlbumId : id,
      page          : 1,
      perPage       : 500,
    },
  }).then(response => {
    const songs = [];
    for (const i in response.data.data) {
      songs.push({
        url      : response.data.data[i].url,
        filename : response.data.data[i].music_title,
        id       : response.data.data[i].mobile_music_id,
        length   : response.data.data[i].length,
      });
    }

    commit('SET_ALBUM_SONGS', songs);
  });
}

const clearAlbumSongs = ({ commit }) => {
  commit('CLEAR_ALBUM_SONGS');
}

const removeAlbumSong = ({ commit }, song) => {
  commit('REMOVE_ALBUM_SONG', song);
}

const getChallengesAlbum = ({ commit }, albumId) => {
  Axios.get('mobile/api/challenges_album', {
    params : {
      id : albumId,
    },
  }).then(response => {
    commit('SET_CHALLENGES_ALBUM', response.data.item);
  })
}

const getChallengesAlbumSongs = ({ commit }, id) => {
  Axios.get('/mobile/api/challenges_album/songs', {
    params : {
      challengesAlbumId : id,
      page              : 1,
      perPage           : 500,
    },
  }).then(response => {
    const songs = [];
    for (const i in response.data.data) {
      songs.push({
        url      : response.data.data[i].url,
        filename : response.data.data[i].music_title,
        id       : response.data.data[i].challenges_music_id,
      });
    }

    commit('SET_CHALLENGES_ALBUM_SONGS', songs);
  });
}

const clearChallengesAlbumSongs = ({ commit }) => {
  commit('CLEAR_CHALLENGES_ALBUM_SONGS');
}

const removeChallengesAlbumSong = ({ commit }, song) => {
  commit('REMOVE_CHALLENGES_ALBUM_SONG', song);
}

const getFeaturedVideos = ({ commit, rootState }) => {
  Axios.get('api/media/featured', {
    params : {
      page    : 1,
      perPage : 100,
    },
  }).then(response => {
    const subs = rootState.subscription.subscription;
    const user = rootState.user.user;
    const videos = [];
    let allowedUser = true;

    if (subs.class_name === 'free' || user.user_type === 1)
      allowedUser = false;
    
    if (response.data.result.data.length > 0) {
      for (const item of response.data.result.data) {
        videos.push({
          'name'      : item.media_title,
          'link'      : `/media/${item.media_id}/${item.wistia_id}`,
          'thumbnail' : item.orig_thumbnail,
          'is_free'   : item.is_free,
          'disabled'  : (item.is_free === 0 && !allowedUser) ? true : false,
        });
      }
    }

    commit('SET_FEATURED_VIDEOS', videos);
  });
}

const getNewVideos = ({ commit, rootState }) => {
  Axios.get('api/media/new')
    .then(response => {
      const subs = rootState.subscription.subscription;
      const user = rootState.user.user;
      const videos = [];
      let allowedUser = true;

      if (subs.class_name === 'free' || user.user_type === 1)
        allowedUser = false;

      if (response.data.videos.length > 0) {
        for (const item of response.data.videos) {
          videos.push({
            name      : item.media_title,
            link      : `/media/${item.media_id}/${item.wistia_id}`,
            thumbnail : item.thumbnail,
            isFree    : item.is_free,
            disabled  : (item.is_free === 0 && !allowedUser) ? true : false,
          });
        }
      }

      commit('SET_NEW_VIDEOS', videos);
    });
}

export {
  getAlbums,
  getAlbum,
  getAlbumSongs,
  clearAlbumSongs,
  removeAlbumSong,
  getChallengesAlbum,
  getChallengesAlbumSongs,
  clearChallengesAlbumSongs,
  removeChallengesAlbumSong,
  getFeaturedVideos,
  getNewVideos,
}