import Axios from '../../../axios-auth';

const getLevels = ({ commit }) => {
  Axios.get('/api/levels')
    .then(response => {
      commit('SET_LEVELS', response.data);
    })
}

export {
  getLevels,
}