import Axios from '../../../axios-auth';

const getCreatedClassrooms = ({ commit }) => {
  Axios.get('api/classroom/created', {
    params : {
      userId  : JSON.parse(localStorage.user).user_id,
      page    : 1,
      perPage : 500,
    },
  }).then(response => {
    commit('SET_CREATED_CLASSROOMS', response.data.data);
  });
}

const getUserClassrooms = ({ commit }) => {
  Axios.get('api/classroom/my', {
    params : {
      userId : JSON.parse(localStorage.user).user_id,
    },
  }).then(response => {
    commit('SET_USER_CLASSROOMS', response.data);
  })
}

export {
  getCreatedClassrooms,
  getUserClassrooms,
}