const SET_ERROR = (state, payload) => {
  state.error = payload;
}

const CLEAR_ERROR = (state) => {
  state.error = null;
}

const SET_ADMIN_NOTIFS = (state, payload) => {
  state.adminNotifs = payload;
}

const CLEAR_ADMIN_NOTIFS = (state) => {
  state.adminNotifs = null;
}

const SET_ADMIN_BAR_NOTIFS = (state, payload) => {
  state.adminBarNotifs = payload;
}

const CLEAR_ADMIN_BAR_NOTIFS = (state) => {
  state.adminBarNotifs = null;
}

const SET_ADMIN_LIST_NOTIFS = (state, payload) => {
  state.adminListNotifs = payload;
}

const CLEAR_ADMIN_LIST_NOTIFS = (state) => {
  state.adminListNotifs = null;
}

const SET_ACTIVITY_NOTIFS = (state, payload) => {
  state.activityNotifs = payload;
}

const CLEAR_ACTIVITY_NOTIFIS = (state) => {
  state.activityNotifs = null;
}

export {
  SET_ERROR,
  CLEAR_ERROR,
  SET_ADMIN_NOTIFS,
  CLEAR_ADMIN_NOTIFS,
  SET_ADMIN_BAR_NOTIFS,
  CLEAR_ADMIN_BAR_NOTIFS,
  SET_ADMIN_LIST_NOTIFS,
  CLEAR_ADMIN_LIST_NOTIFS,
  SET_ACTIVITY_NOTIFS,
  CLEAR_ACTIVITY_NOTIFIS,
}