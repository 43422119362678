const SET_FAVORITE_MUSIC = (state, payload) => {
  state.music = payload;
}

const SET_FAVORITE_VIDEOS = (state, payload) => {
  state.videos = payload;
}

const SET_FAVE_MUSIC_IDS = (state, payload) => {
  state.musicIds = payload;
}

const SET_FAVE_VIDEO_IDS = (state, payload) => {
  state.videoIds = payload;
}

export {
  SET_FAVORITE_MUSIC,
  SET_FAVORITE_VIDEOS,
  SET_FAVE_MUSIC_IDS,
  SET_FAVE_VIDEO_IDS,
}