import Axios from '../../../axios-auth';

const getCategories = ({ commit }) => {
  Axios.get('/api/categoriesAdmin/all')
    .then(response => {
      commit('SET_CATEGORIES', response.data);
    })
}

const getInteractCategories = ({ commit }) => {
  Axios.get('api/interact/categoriesAdmin/all')
    .then(response => {
      commit('SET_INTERACT_CATEGORIES', response.data);
    });
}

const getCharactersMedia = ({ commit }) => {
  const charsMedia = [
    {
      title  : 'PJ Panda',
      videos : [
        {
          'media_id'    : 72,
          'media_title' : 'Baby Shark Remix',
          'wistia_id'   : 'q5qcnmx8ij',
          'thumbnail'   : 'https://embed-ssl.wistia.com/deliveries/354988838ae7f6b2e74b38bb34b5c322.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=73',
        },
        {
          'media_id'    : 71,
          'media_title' : 'Read a Book',
          'wistia_id'   : 'q6u9ocjnng',
          'thumbnail'   : 'https://embed-ssl.wistia.com/deliveries/ec8e4756a88e22010043d85e5f8ff139.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=45',
        },
        {
          'media_id'    : 70,
          'media_title' : 'Head, Shoulders, Knees, Toes!',
          'wistia_id'   : '073pyh1w99',
          'thumbnail'   : 'https://embed-ssl.wistia.com/deliveries/1368fe1be5ce09b0e2d8f1721fc34abf.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=57',
        },
        {
          'media_id'    : 69,
          'media_title' : 'Wheels On The Bus',
          'wistia_id'   : 'tau5oth3gu',
          'thumbnail'   : 'https://embed-ssl.wistia.com/deliveries/acd907e7fffb1efbde3d2e83b88d3e5d.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=81',
        },
        {
          'media_id'    : 68,
          'media_title' : 'Be Active',
          'wistia_id'   : 'ifqajvpsh8',
          'thumbnail'   : 'https://embed-ssl.wistia.com/deliveries/b8b6a531eefde6ccb45aedf1cdd0daf8.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=78',
        },
      ],
    },
    {
      title  : 'Cub Cakes',
      videos : [
        {
          "media_title" : "I Promise",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/d3de759d3c6856ed2cab4cd0905bf13f.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=64",
          "media_id"    : 62,
          "wistia_id"   : "nhy4bj3acp",
        },
        {
          "media_title" : "Johnny Johnny",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/416c4ebcc126672f10cefcc6a25c3a46.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=81",
          "media_id"    : 60,
          "wistia_id"   : "psyj3ea216",
        },
        {
          "media_title" : "SMILE KIDS",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/ff236b1c69efee8d58172f408e855af6.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=95",
          "media_id"    : 33,
          "wistia_id"   : "99aan9zm8i",
        },
        {
          "media_title" : "Birthday Song",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/09f2aa50f40436caff6833d09add9c23.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=79",
          "media_id"    : 26,
          "wistia_id"   : "qa0b34u3bm",
        },
      ],
    },
    {
      title  : 'Champ The Mascot',
      videos : [
        {
          "media_title" : "Souljah Boy",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/ceaa95109fc83a9a680512fdf5db92f2.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=61",
          "media_id"    : 61,
          "wistia_id"   : "yv86cq1o86",
        },
        {
          "media_title" : "Shout!",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/5a40f4e519e00b83eadf6c0a7e170336.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=91",
          "media_id"    : 58,
          "wistia_id"   : "t20dr06g1d",
        },
        {
          "media_title" : "Macarena",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/f5fd557a5928e5169880a44a489544c8.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=50",
          "media_id"    : 57,
          "wistia_id"   : "h69y3y59r5",
        },
        {
          "media_title" : "Whip & Nay Nay",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/263e1595d263a7824aae434826179e4f.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=58",
          "media_id"    : 56,
          "wistia_id"   : "6rpltgccos",
        },
        {
          "media_title" : "Bobble Head",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/ef2d06825c4c4c3dd0a5380f467bfe4b.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=47",
          "media_id"    : 54,
          "wistia_id"   : "6tr4hx0jns",
        },
      ],
    },
    {
      title  : 'Rapping Roy',
      videos : [
        {
          "media_title" : "Smile Kids!",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/4074a51e87cfa0eec9c211f8667370c0.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=89",
          "media_id"    : 59,
          "wistia_id"   : "xp4hw2oeaj",
        },
        {
          "media_title" : "Kipp Hop",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/972432e9103f0942175ce032764136a6.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=84",
          "media_id"    : 55,
          "wistia_id"   : "efsn7p4x77",
        },
        {
          "media_title" : "Teacher Challenge",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/9e11c7a476f58992d68e464fed734727.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=61",
          "media_id"    : 53,
          "wistia_id"   : "bg5qzuljba",
        },
        {
          "media_title" : "Clap Your Hands!",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/07b35eab5d4424d99a1248a20595fe55.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=85",
          "media_id"    : 51,
          "wistia_id"   : "6r0vp0o753",
        },
        {
          "media_title" : "Wiggle!",
          "thumbnail"   : "https://embed-ssl.wistia.com/deliveries/794c7e3217935587761006a3e17aee7c.jpg?image_crop_resized=200x120&image_quality=100&ssl=true&video_still_time=77",
          "media_id"    : 49,
          "wistia_id"   : "5uy4q3w9cz",
        },
      ],
    },
  ];

  commit('SET_CHARACTERS_MEDIA', charsMedia);
}

export {
  getCategories,
  getInteractCategories,
  getCharactersMedia,
}