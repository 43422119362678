const accessToken = state => state.accessToken;
const refreshToken = state => state.refreshToken;
const isAuthenticated = state => state.accessToken !== null;
const isLoggedIn = state => state.accessToken !== null;
const authStatus = state => state.status;
const isPinVerified = state => state.isPinVerified;
const logoutStatus = state => state.logoutStatus;

export {
  accessToken,
  refreshToken,
  isAuthenticated,
  isLoggedIn,
  authStatus,
  isPinVerified,
  logoutStatus,
}