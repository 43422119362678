import Axios from '../../../axios-auth';

const getDefaultAvatars = ({ commit }) => {
  Axios.get('/api/assets/default_avatars')
    .then(response => {
      commit('SET_DEFAULT_AVATARS', response.data);
    })
}

export {
  getDefaultAvatars,
}