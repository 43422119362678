const music = state => state.music;
const videos = state => state.videos;
const musicIds = state => state.musicIds;
const videoIds = state => state.videoIds;

export {
  music,
  videos,
  musicIds,
  videoIds,
}