import Axios from '../../../axios-auth';
import _Axios from 'axios';
import Router from '../../../routes';
import Vue from 'vue';
import _ from 'lodash';

const login = ({ commit, dispatch }, authData) => {
  commit('AUTH_REQUEST');
  const path = Router.currentRoute;
  Axios.post('/auth/login', authData)
    .then(async resp => {
      const user = resp.data.user;
      if (path.fullPath.includes('/admin') && user.user_type !== 0) {
        commit('notification/SET_ERROR', 'Admin users can only login here', {
          root : true});
        commit('AUTH_ERROR');
      } else if (!path.fullPath.includes('/admin') && user.user_type === 0) {
        commit('notification/SET_ERROR', 'H3 users can only login here', {
          root : true});
        commit('AUTH_ERROR');
      } else {
        dispatch('setTokens', resp.data);
        dispatch('user/getUser', null, {root : true});
        dispatch('gamification/getLevels', null, {root : true});
        commit('AUTH_SUCCESS');
      }
    }).catch(err => {
      commit('notification/SET_ERROR', err.response.data.msg, {root : true});
      commit('AUTH_ERROR');
    });
}

const socialLogin = ({ dispatch }, tokens) => {
  dispatch('setTokens', tokens);
  dispatch('user/getUser', null, {root : true});
  dispatch('gamification/getLevels', null, {root : true});
}

const autoLogin = ({ commit, dispatch }) => {
  if (!localStorage.accessToken || !localStorage.refreshToken)
    return;
  
  // Set Tokens
  const expiry = localStorage.expiryAccessTokenUnix;
  if (Vue.moment().isSameOrAfter(Vue.moment.unix(expiry)))
    dispatch('getRefreshToken');
  commit('SET_TOKENS', {
    accessToken  : localStorage.accessToken,
    refreshToken : localStorage.refreshToken,
  });

  // Set Current User Profile data
  if (localStorage.getItem('profile')) {
    const profile = JSON.parse(localStorage.getItem('profile'));
    commit('profile/SET_INFO', profile, {root : true});
  }

  // Set User data
  if (!localStorage.getItem('user'))
    dispatch('user/getUser', null, {root : true});
  else {
    const user = JSON.parse(localStorage.getItem('user'));
    commit('user/SET_USER', user, {root : true});
    
    // Redirect to Dashboard Page
    Router.replace('/dashboard');
  }

  // Get User Level
  dispatch('gamification/getLevels', null, {root : true});
}

const getRefreshToken = ({ commit, dispatch }) => {
  _Axios.post('/auth/token', {}, {
    baseURL : process.env.VUE_APP_ROOT_API,
    headers : {
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer ' + localStorage.getItem('refreshToken'),
    },
  }).then(response => {
    if (response.status == 200) {
      localStorage.accessToken = response.data.accessToken;
      localStorage.expiryAccessTokenUnix = response.data.expiryAccessTokenUnix;
      localStorage.expiryAccessTokenDate = response.data.expiryAccessTokenDate;
    
      commit('SET_TOKENS', {
        accessToken  : response.data.accessToken,
        refreshToken : localStorage.refreshToken,
      });
    }
  }).catch((error) => {
    if (error.response.status === 500) {
      if (error.response.data.error.name === 'TokenExpiredError') {
        // notify of expiry and logout
        Vue.notify({
          group : 'validation-notification',
          title : 'You\'re login has expired.',
          text  : 'Please Login again. Thank You.',
        });
        dispatch('logout');
      }
    }
  });
}

// eslint-disable-next-line no-undef
const logout = _.debounce(function({ commit, dispatch }) {
  commit('SET_LOGOUT_STATUS', 'loading');
  Axios.post('/auth/logout', {
    accessToken  : localStorage.accessToken,
    refreshToken : localStorage.refreshToken,
  }).then(() => {
    commit('SET_LOGOUT_STATUS', 'success');
    const maintenance = localStorage.maintenance;
    commit('CLEAR_TOKENS');
    commit('user/CLEAR_USER', null, {root : true});
    window.localStorage.clear();
    localStorage.maintenance = maintenance;
    dispatch('web/getSettings', null, {root : true});
    Router.replace('/');
  }).catch(() => {
    commit('SET_LOGOUT_STATUS', 'error');
    Vue.notify({
      group : 'notif',
      title : 'Failed',
      text  : 'Oops! Something went wrong!',
    });
  });
}, 500);

// const logout = _.debounce(function({ commit }) {
//   Axios.post('/auth/logout', {
//     accessToken  : localStorage.accessToken,
//     refreshToken : localStorage.refreshToken,
//   }).then(() => {
//     commit('CLEAR_TOKENS');
//     commit('user/CLEAR_USER', null, {root : true});
//     window.localStorage.clear();
//     Router.replace('/');
//   }).catch(() => {
//     Vue.notify({
//       group : 'notif',
//       title : 'Failed',
//       text  : 'Oops! Something went wrong!',
//     });
//   });
// }, 500);

const setTokens = ({ commit }, data) => {
  localStorage.accessToken = data.accessToken;
  localStorage.refreshToken = data.refreshToken;
  localStorage.expiryAccessTokenUnix = data.expiryAccessTokenUnix;
  localStorage.expiryAccessTokenDate = data.expiryAccessTokenDate;
  localStorage.expiryRefreshTokenUnix = data.expiryRefreshTokenUnix;
  localStorage.expiryRefreshTokenDate = data.expiryRefreshTokenDate;

  commit('SET_TOKENS', {
    accessToken  : data.accessToken,
    refreshToken : data.refreshToken,
  });
}

const setPinVer = ({ commit }, data) => {
  commit('SET_PIN_VER', data);
}

export{
  autoLogin,
  login,
  logout,
  getRefreshToken,
  socialLogin,
  setTokens,
  setPinVer,
}