import Axios from '../../../axios-auth';

const getAdminNotifs = ({ commit }) => {
  Axios.get('api/notification/admin', {
    params : {
      page    : 1,
      perPage : 10,
    },
  }).then(response => {
    commit('SET_ADMIN_NOTIFS', response.data);
  });
}

const getAdminBarNotifs = ({ commit }) => {
  Axios.get('api/notification/admin', {
    params : {
      page          : 1,
      perPage       : 10,
      filterDisplay : 'bar',
      platform      : 'website',
    },
  }).then(response => {
    commit('SET_ADMIN_BAR_NOTIFS', response.data);
  });
}

const getAdminListNotifs = ({ commit }) => {
  Axios.get('api/notification/admin', {
    params : {
      page          : 1,
      perPage       : 30,
      filterDisplay : 'list',
      platform      : 'website',
    },
  }).then(response => {
    commit('SET_ADMIN_LIST_NOTIFS', response.data);
  }).catch(() => {
    commit('SET_ADMIN_LIST_NOTIFS', []);
  });
}

const getActivityNotifs = ({ commit }) => {
  Axios.get('api/notification/activity', {
    params : {
      page     : 1,
      perPage  : 30,
      platform : 'website',
    },
  }).then(response => {
    commit('SET_ACTIVITY_NOTIFS', response.data);
  }).catch(() => {
    commit('SET_ACTIVITY_NOTIFS', []);
  });
}

export {
  getAdminNotifs,
  getAdminBarNotifs,
  getAdminListNotifs,
  getActivityNotifs,
}