import Axios from 'axios';
import moment from 'moment';
import store from './store';
import Vue from 'vue';

// eslint-disable-next-line no-undef
const fetchRefreshToken = _.debounce(() => {
  store.dispatch('auth/getRefreshToken');
}, 1000);

const instance = Axios.create({
  baseURL : process.env.VUE_APP_ROOT_API,
  headers : {
    'Content-Type' : 'application/json',
  },
});

instance.interceptors.request.use(config => {
  if (localStorage.getItem('accessToken')) {
    config.headers['Authorization'] = 'Bearer ' +
      localStorage.getItem('accessToken');
  } else
    config.headers['Authorization'] = '';
  

  if (localStorage.getItem('expiryAccessTokenUnix') !== null) {
    if (moment().isSameOrAfter(moment.unix(
      localStorage.getItem('expiryAccessTokenUnix'))))
      fetchRefreshToken();
  }

  return config;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.data.errorCode === '9003' ||
      error.response.data.errorCode === '9002' ||
      error.response.data.errorCode === '9001' ||
      error.response.data.errorCode === '9004') {
      // Deleted or Deactivated Account Notification
      Vue.notify({
        group : 'validation-notification',
        title : error.response.data.msg,
        text  : 'Please contact Admin if you have questions.',
      });
      return store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  }
);

// instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance;
