const SET_CREATED_CLASSROOMS = (state, payload) => {
  state.createdClassrooms = payload;
}

const SET_USER_CLASSROOMS = (state, payload) => {
  state.userClassrooms = payload;
}

export {
  SET_CREATED_CLASSROOMS,
  SET_USER_CLASSROOMS,
}