import Axios from '../../../axios-auth';
import moment from 'moment-timezone';

const getLiveBroadcastLink = ({ commit }) => {
  Axios.get('/api/live_broadcast/link')
    .then(response => {
      commit('SET_LIVE_BROADCAST_LINK', response.data.active.html_embed);
    });
}

const getScheduledLiveBroadcast = ({ commit }) => {
  const today = new Date();
  const todayUTC = moment.utc(today);
  const tomUTC = moment(todayUTC).add(1, 'days');
  
  Axios.get('/api/live_broadcast/scheduled', {
    params : {
      curDate : todayUTC.format('YYYY-MM-DD HH:mm:ss'),
      tomDate : tomUTC.format('YYYY-MM-DD HH:mm:ss'),
    },
  }).then(response => {
    commit('SET_SCHEDULED_LIVE_BROADCAST', response.data);
  });
}

const getSchedules = ({ commit }, influencerId) => {
  const today = new Date();
  const todayUTC = moment.utc(today);

  Axios.get('/api/broadcast/slot/all', {
    params : {
      influencerId : influencerId,
      curDate      : todayUTC.format('YYYY-MM-DD HH:mm:ss'),
    },
  }).then(response => {
    commit('SET_SCHEDULES', response.data);
  });
}

export {
  getLiveBroadcastLink,
  getScheduledLiveBroadcast,
  getSchedules,
}