import Axios from '../../../axios-auth';

const getRecentSongs = ({ commit }) => {
  Axios.get('api/playlist/newMusic')
    .then(response => {
      const result = response.data.result;
      const formatted = {
        pagination : {
          'total'         : result.length,
          'per_page'      : 10,
          'current_page'  : 1,
          'last_page'     : 1,
          'next_page_url' : null,
          'prev_page_url' : null,
          'from'          : 1,
          'to'            : result.length,
        },
        songs : [],
      };

      for (let i = 0; i < result.length; i++) {
        formatted.songs.push({
          id     : result[i].mobile_music_id,
          title  : result[i].music_title,
          album  : result[i].album_name,
          length : result[i].length,
          url    : result[i].url,
        });
      }
      commit('SET_RECENT_SONGS', (result.length > 0) ? formatted : []);

      // commit('SET_RECENT_SONGS', response.data.result);
    });
}

const clearRecentSongs = ({ commit }) => {
  commit('CLEAR_RECENT_SONGS');
}

const getPlaylistSongIds = ({ commit }, id) => {
  Axios.get('api/playlist/songIds', {
    params : {
      plistId : id,
    },
  }).then(response => {
    const result = response.data.result.map(item => item.mobile_music_id);
    commit('SET_PLAYLIST_SONG_IDS', result);
  });
}

const clearPlaylistSongIds = ({ commit }) => {
  commit('CLEAR_PLAYLIST_SONG_IDS');
}

const getPlaylistBanners = ({ commit }) => {
  const faveBanner =
    require('../../../assets/images/music/Favorites-Playlist-Banner.jpg');
  const favePhoto =
    require('../../../assets/images/music/My-Favorites-Cover-Art.png');
    
  Axios.get('api/playlist/banner', {
    params : {
      page    : 1,
      perPage : 100,
    },
  }).then(response => {
    const result = response.data.data.data;
    const musBanners = [{
      id        : 0,
      name      : 'Favorites',
      link      : null,
      thumbnail : faveBanner,
      premium   : 0,
      photo     : favePhoto,
    }];
    const banners = [];

    for (let i = 0; i < result.length; i++) {
      banners.push({
        id        : result[i].music_playlist_id,
        name      : result[i].name,
        thumbnail : result[i].banner_url,
        premium   : result[i].premium,
        photo     : result[i].thumbnail_url,
      });
      musBanners.push(banners[i]);
    }
    
    commit('SET_PLAYLIST_BANNERS', banners);
    commit('SET_MUSIC_PLAYLIST_BANNERS', musBanners);
  });
}

const clearPlaylistBanners = ({ commit }) => {
  commit('CLEAR_PLAYLIST_SONG_IDS');
}

export {
  getRecentSongs,
  clearRecentSongs,
  getPlaylistSongIds,
  clearPlaylistSongIds,
  getPlaylistBanners,
  clearPlaylistBanners,
}