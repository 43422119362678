const SET_SUBSCRIPTION = (state, payload) => {
  state.subscription = payload;
};

const CLEAR_SUBSCRIPTION = (state) => {
  state.subscription = {};
};

const SET_PAYMENT = (state, payload) => {
  state.payment = payload;
};

const CLEAR_PAYMENT = (state) => {
  state.payment = {};
};

const SET_SUBSCRIPTION_USERS = (state) => {
  state.users = {};
};

const SET_SUBSCRIPTION_PLANS = (state, payload) => {
  state.plans = payload;
};

const SET_USER_PROFILES = (state, payload) => {
  state.userProfiles = payload;
}

export {
  SET_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION,
  SET_PAYMENT,
  CLEAR_PAYMENT,
  SET_SUBSCRIPTION_USERS,
  SET_SUBSCRIPTION_PLANS,
  SET_USER_PROFILES,
}