import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  accessToken   : null,
  refreshToken  : null,
  status        : null,
  isPinVerified : false,
  logoutStatus  : null,
}

export default {
  namespaced : true,
  state,
  actions,
  mutations,
  getters,
}