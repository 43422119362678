const info = state => state.info;
const userContents = state => state.userContents;
const musicFavorites = state => state.musicFavorites;
const videoFavorites = state => state.videoFavorites;
const recentlyPlayed = state => state.recentlyPlayed;
const initVideoFave = state => state.initVideoFave;
const videoFaveStatus = state => state.videoFaveStatus;
const musicFaveStatus = state => state.musicFaveStatus;

export {
  info,
  userContents,
  musicFavorites,
  videoFavorites,
  recentlyPlayed,
  initVideoFave,
  videoFaveStatus,
  musicFaveStatus,
}
