import Vue from 'vue';
import Vuex from 'vuex';

// modules
import authModule from './modules/auth/index';
import broadcastModule from './modules/broadcast/index';
import categoriesModule from './modules/categories/index';
import classroomModule from './modules/classroom/index';
import gamificationModule from './modules/gamification/index';
import mediaModule from './modules/media/index';
import notificationModule from './modules/notification/index';
import userModule from './modules/user/index';
import staticAssetModule from './modules/staticAsset/index';
import contentReportingModule from './modules/contentReporting/index';
import addressModule from './modules/address/index';
import announcementModule from './modules/announcement/index';
import subscriptionModule from './modules/subscription/index';
import playlistModule from './modules/playlist/index';
import favoriteModule from './modules/favorite/index';
import profileModule from './modules/profile/index';
import interactiveModule from './modules/interactive/index';
import webSettingsModule from './modules/web/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules : {
    auth             : authModule,
    broadcast        : broadcastModule,
    categories       : categoriesModule,
    classroom        : classroomModule,
    gamification     : gamificationModule,
    media            : mediaModule,
    notification     : notificationModule,
    user             : userModule,
    staticAsset      : staticAssetModule,
    contentReporting : contentReportingModule,
    address          : addressModule,
    announcement     : announcementModule,
    subscription     : subscriptionModule,
    playlist         : playlistModule,
    favorite         : favoriteModule,
    profile          : profileModule,
    interactive      : interactiveModule,
    web              : webSettingsModule,
  },
});