import store from './store';
import Axios from './axios-auth';

let redirectionUrl = null;

export function isAdmin(to, from, next) {
  if (store.getters['auth/isAuthenticated']) {
    if (store.getters['user/user'].user_type === 0) {
      if (redirectionUrl) {
        const url = redirectionUrl;
        redirectionUrl = null;
        return next(url);
      }
      next();
    } else
      next('/error?status=\'401\'');
  } else {
    redirectionUrl = to.path;
    next('/admin/login');
  }
}

export function isUser(to, from, next) {
  if (store.getters['auth/isAuthenticated']) {
    if (redirectionUrl) {
      const url = redirectionUrl;
      redirectionUrl = null;
      return next(url);
    }

    if (store.getters['user/user'].user_type === 6) {
      // For enterprise users
      switch (to.path) {
      case '/subscription' :
      case '/subscription-old' :
      case '/payment-details' :
      case '/profile' :
        next();
        break;
      default :
        next('/error?status=401');
        break;
      }
    } else if (store.getters['user/user'].user_type !== 0)
      next();
    else
      next('/error?status=401');
  } else {
    redirectionUrl = to.path;
    next('/login');
  }
}

export async function isAuthenticated(to, from, next) {
  if (store.getters['auth/isAuthenticated']) {
    switch(store.getters['user/user'].user_type) {
    case 0 :
      next('/admin/dashboard');
      break;
    case 6 :
      next('/subscription');
      break;
    default :
      next('/dashboard'); //('/browse/main');
      break;
    }
  } else
    next();
}

// export function isAuthenticated(to, from, next) {
//   if (store.getters['auth/isAuthenticated']) {
//     if (store.getters['user/user'].user_type != 0)
//       next('/browse/main');
//     else
//       next('/admin');
//   } else
//     next();
// }

export function isActivationUsed(to, from, next) {
  if (store.getters['auth/isAuthenticated']) {
    if (store.getters['user/user'].activation_used != 0)
      next();
    else
      next('/dashboard'); //('/browse/main');
  }
}

export function isFriend(to, from, next) {
  Axios.get('/api/friend/check', {
    params : {
      friendId : to.params.userId,
    },
  }).then(response => {
    if (response.data)
      next();
    else
      next('/unauthorized');
  })
}

export function isLinkValid(to, from, next) {
  if (to.params.shortToken) {
    Axios.get('auth/validateToken', {
      params : {
        token : to.params.shortToken,
      },
    }).then(response => {
      if (response.data)
        next();
      else
        next('/expired-link');
    })
  } else
    next();
}

export function checkActivity(to, from, next) {
  if (localStorage.getItem('friendChatId') &&
    localStorage.getItem('friendUserType')) {
    // accessed Safe Circle Invite link
    next('/download');
  } else
    next();
}

export function isAdult(to, from, next) {
  // if (store.getters['user/user'].user_type === 1 ||
  //   store.getters['profile/info'].user_profiles_id)
  if (store.getters['user/user'].user_type === 1)
    next('/unauthorized'); //next('/error?status=401');
  else {
    if (store.getters['auth/isPinVerified'] || to.path === '/subscription')
      next();
    else
      next('/dashboard');
  }
}

export function checkDOB(to, from, next) {
  if (store.getters['web/isMaintenance'] === 'true' ||
    localStorage.maintenance === 'true')
    next('/maintenance');
  else {
    if (!localStorage.getItem('birthdate'))
      next();
    else {
      const age = localStorage.getItem('age');
      if (from.path === '/free-sign-up') {
        if (to.path === '/sign-up' && parseInt(age) < 18) {
          localStorage.removeItem('birthdate');
          localStorage.removeItem('age');
          next('/');
        } else
          next();
      } else if (from.path === '/sign-up') {
        if (to.path === '/free-sign-up' && parseInt(age) >= 18) {
          localStorage.removeItem('birthdate');
          localStorage.removeItem('age');
          next('/');
        } else
          next();
      } else {
        localStorage.removeItem('birthdate');
        localStorage.removeItem('age');
        next();
      }
    }
  }
}

export function isMaintenance(to, from, next) {
  if (store.getters['web/isMaintenance'] === 'true' ||
    localStorage.maintenance === 'true')
    next('/maintenance');
  else
    next();
}