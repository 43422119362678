const SET_INTERACTIVE_CATEGORIES = (state, payload) => {
  state.categories = payload;
}

const SET_CURRENT_CATEGORY = (state, payload) => {
  state.current = payload;
}

export {
  SET_INTERACTIVE_CATEGORIES,
  SET_CURRENT_CATEGORY,
}