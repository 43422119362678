const SET_USER = (state, payload) => {
  state.user = payload;
}

const CLEAR_USER = (state) => {
  state.user = {};
}

export {
  SET_USER,
  CLEAR_USER,
}