const subscription = state => state.subscription;
const payment = state => state.payment;
const users = state => state.users;
const plans = state => state.plans;
const userProfiles = state => state.userProfiles;

export {
  subscription,
  payment,
  users,
  plans,
  userProfiles,
}